<template>
  <div>
    <div class="bill-title">物资调配</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="项目编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(4)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="调配类型" prop="allottedType">
            <el-select v-model="form.allottedType" @change="typeChange">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="发出仓库"
            prop="sourceId"
            v-if="form.allottedType == 1 || form.allottedType == 2"
          >
            <base-select
              info="Store"
              v-model="form.sourceId"
              @input="sourceChange"
            ></base-select>
          </el-form-item>
          <el-form-item label="发出项目" prop="sourceId" v-else>
            <project-select
              v-model="form.sourceId"
              @input="sourceChange"
            ></project-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="接收仓库"
            prop="targetId"
            v-if="form.allottedType == 2 || form.allottedType == 3"
          >
            <base-select info="Store" v-model="form.targetId"></base-select>
          </el-form-item>
          <el-form-item label="接收项目" prop="targetId" v-else>
            <project-select
              :state="[1, 2]"
              :addId="addProjectId"
              v-model="form.targetId"
            ></project-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="摘要">
        <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
      </el-form-item>
      <el-form-item label="单据附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <strong>物资明细</strong>
        </template>
        <el-button-group>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="addDetail"
          ></el-button>
          <el-button size="mini" type="success" @click="addMultipleDetail">
            <span class="iconfont iconduoxuan" style="font-size: 12px"></span>
          </el-button>
        </el-button-group>
      </el-form-item>
      <div style="margin: -8px 0px 8px 80px">
        <el-table :data="detailList" @row-dblclick="editDetail" stripe border>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            label="编号"
            prop="goodsCode"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="goodsName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="规格"
            prop="goodsSpec"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="单位"
            prop="goodsUnit"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">{{
              scope.row.quantity | thousands
            }}</template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" width="65">
            <template slot-scope="scope">
              <el-tag
                v-if="!scope.row.isStop"
                type="danger"
                effect="dark"
                @click="deleteDetail(scope.row)"
                >移除</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item>
        <div v-if="canntEditMsg" style="display: inline">
          <span style="color: red; margin-right: 10px"
            >{{ canntEditMsg }} 单据不可编辑</span
          >
          <el-button type="primary" @click="print(false)" v-if="form.id"
            >单据打印</el-button
          >
        </div>
        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
          <el-button type="primary" @click="print(false)" v-if="form.id"
            >单据打印</el-button
          >
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>
    <div>
      <goods-detail
        :item="detail"
        :sourceId="form.sourceId"
        @success="updateDetail"
      ></goods-detail>
      <multiple-detail
        :item="multiple"
        :sourceId="form.sourceId"
        @success="addMultiple"
      ></multiple-detail>
    </div>
  </div>
</template>

<script>
import AllottedType from "@/views/allotted/AllottedType.js";
import billcode from "@/common/billcodeMixins.js";
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import GoodsDetail from "@/views/allotted/GoodsDetail.vue";
import MultipleDetail from "@/views/allotted/MultipleDetail.vue";
import OperatorBox from "@/components/OperatorBox";
import billPrint from "@/common/billPrint";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    BaseSelect,
    ProjectSelect,
    GoodsDetail,
    MultipleDetail,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "物资调配",
      typeList: AllottedType,
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        allottedType: [this.$g.required],
        sourceId: [this.$g.required],
        targetId: [
          this.$g.required,
          {
            validator: (rule, value, callback) => {
              if (this.form.sourceId && value == this.form.sourceId) {
                return callback(new Error("接收和发出不能相同"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      detailList: [],
      detail: {},
      multiple: {},

      stopList: [], //停用基础信息
      canntEditMsg: "", //不可编辑的提示信息
      addProjectId: null, //不可用项目Id(编辑时接收项目可能要显示)
    };
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  beforeDestroy() {
    this.stopList.forEach((item) => {
      this.$store.commit("removeBaseInfo", {
        info: item.info,
        id: item.baseInfo.id,
      });
    });
  },
  methods: {
    typeChange() {
      this.form.sourceId = "";
      this.form.targetId = "";
      this.detailList = [];
    },
    sourceChange() {
      this.detailList = [];
    },
    init(id) {
      this.stopList = [];
      this.canntEditMsg = "";
      this.addProjectId = null;
      //编辑
      if (id) {
        this.$get("Allotted/Detail", {
          id,
        }).then((r) => {
          this.routeTab = r.bill.billCode;
          this.form = { ...r.bill };
          this.detailList = [...r.detail];

          this.addProjectId = r.addProjectId;
          this.stopList = r.stopList;
          this.canntEditMsg = r.canntEditMsg;
          this.stopList.forEach((item) => {
            this.$store.commit("addBaseInfo", {
              info: item.info,
              item: item.baseInfo,
            });
          });
        });
      }
      //新增
      else {
        this.form = {
          billCode: "",
          billDate: new Date(),
          allottedType: 1,
          sourceId: "",
          targetId: "",
          remark: "",
          attachId: guid(),
        };
        this.detailList = [];
        this.getBillCode(4);
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      }
    },
    addDetail() {
      if (!this.form.sourceId) {
        this.$message.error("请选择发出地");
        return;
      }
      this.detail = {};
    },
    addMultipleDetail() {
      if (!this.form.sourceId) {
        this.$message.error("请选择发出地");
        return;
      }
      this.multiple = {};
    },
    addMultiple(array) {
      array.forEach((item) => {
        this.detailList.push(item);
      });
    },
    updateDetail(row) {
      let index = -1;
      this.detailList.forEach((item, i) => {
        if (item.id == row.id) {
          index = i;
        }
      });
      if (index == -1) {
        //添加
        this.detailList.push(row);
      } else {
        //修改
        this.detailList.splice(index, 1, row);
      }
    },
    //双击明细，编辑
    editDetail(row) {
      if (row.isStop) {
        return;
      }
      this.detail = { ...row };
    },
    // 移除明细
    deleteDetail(row) {
      let index = this.detailList.findIndex((d) => {
        return d.id == row.id;
      });
      this.detailList.splice(index, 1);
    },
    //保存
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.detailList.length == 0) {
          this.$message.error("请选择物资明细");
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let bill = { ...this.form };
        let detail = [...this.detailList];
        let url = "";
        if (this.form.id) {
          url = "Allotted/Edit";
        } else {
          url = "Allotted/Add";
        }
        this.$post(url, {
          bill,
          detail,
        })
          .then((r) => {
            this.$message.success("操作成功！");
            this.print(true);

            if (isContinue) {
              this.$tabs.close({
                to: {
                  name: "AllottedItem",
                  query: {},
                },
                refresh: true,
              });
            } else {
              this.$tabs.close();
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    print(savePrint) {
      let bill = { ...this.form };
      let detail = [...this.detailList];
      billPrint(savePrint, "物资调配", bill, detail);
    },
    deleteBill() {
      this.$confirm("将删除此调配, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Allotted/Delete?id=" + this.form.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$g.deleteAttach(this.form.attachId);
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>