//1仓库到项目2仓库到仓库3项目到仓库4项目到项目
const type = [{
    value: 1,
    label: '仓库到项目',
}, {
    value: 2,
    label: '仓库到仓库',

}, {
    value: 3,
    label: '项目到仓库',
}, {
    value: 4,
    label: '项目到项目',

}]

export default type;