<template>
  <div>
    <el-dialog
      title="物资明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="80%"
      :close-on-click-modal="false"
    >
      <el-form size="mini" :inline="true">
        <el-form-item label="物资类别">
          <type-cascader
            v-model="filter.goodsTypeId"
            info="GoodsType"
          ></type-cascader>
        </el-form-item>
        <el-form-item label="过滤">
          <el-input
            v-model="filter.text"
            placeholder="输入关键字搜索"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="总条数">
          <el-tag type="success">{{ totalCount }}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="success" @click="selectAll">全选</el-button>
            <el-button type="info" @click="save">保存关闭</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table :data="array" stripe border v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="编号"
          prop="goodsCode"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="goodsName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="类别"
          prop="goodsTypeName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="规格"
          prop="goodsSpec"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="单位"
          prop="goodsUnit"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="可用数量"
          prop="quantityCanUse"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{
            scope.row.quantityCanUse | thousands
          }}</template>
        </el-table-column>
        <el-table-column label="调出数量">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.quantity"
              @mousewheel.native.prevent
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :current-page.sync="pageIndex"
        :page-size="10"
        :total="pageTotal"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import TypeCascader from "@/components/TypeCascader";
import setName from "@/common/setName";
import guid from "@/common/guid.js";
export default {
  components: {
    TypeCascader,
  },
  props: ["item", "sourceId"],
  data() {
    return {
      visible: false,
      loading: false,
      filter: {
        goodsTypeId: "",
        text: "",
      },
      arrayTotal: [],
      pageIndex: 1,
      pageTotal: 0,
    };
  },
  watch: {
    item: {
      handler: function (params) {
        this.filter.goodsTypeId = "";
        this.filter.text = "";
        this.getData();
        this.visible = true;
      },
      deep: true,
    },
  },
  computed: {
    array() {
      let temp = this.arrayTotal;
      let text = this.filter.text;
      let goodsTypeId = this.filter.goodsTypeId;
      if (text) {
        temp = temp.filter((item) => {
          if (
            item.goodsName.indexOf(text) >= 0 ||
            item.pinYin.indexOf(text.toLowerCase()) >= 0 ||
            item.goodsCode.indexOf(text) >= 0 ||
            (item.goodsSpec && item.goodsSpec.indexOf(text) >= 0)
          ) {
            return true;
          }
        });
      }
      if (goodsTypeId) {
        temp = temp.filter((item) => {
          if (item.goodsTypeId == goodsTypeId) {
            return true;
          }
        });
      }
      this.pageTotal = temp.length;
      let start = (this.pageIndex - 1) * 10;
      return temp.slice(start, start + 10);
    },
    totalCount() {
      let res = 0;
      this.arrayTotal.forEach((item) => {
        let quantity = Number(item.quantity);
        if (quantity != 0) {
          res++;
        }
      });
      return res;
    },
  },
  methods: {
    getData() {
      this.loading = true;
      this.$get("Goods/AllGoodsCanUse", {
        locationId: this.sourceId,
      })
        .then((r) => {
          r.forEach((item) => {
            setName("GoodsType", item, "goodsTypeId", "goodsTypeName");
            item.quantity = null;
            item.remark = "";
          });
          this.arrayTotal = r;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      let res = [];
      let error = "";
      this.arrayTotal.forEach((item) => {
        let quantity = Number(item.quantity);
        if (item.quantityCanUse < quantity) {
          error = `物资编号【${item.goodsCode}】不可大于可用数量！`;
        }
        if (quantity > 0) {
          res.push({
            id: guid(),
            goodsId: item.goodsId,
            goodsCode: item.goodsCode,
            goodsName: item.goodsName,
            goodsSpec: item.goodsSpec,
            goodsUnit: item.goodsUnit,
            quantity: quantity,
            remark: item.remark,
          });
        }
      });
      if (error) {
        this.$message.error(error);
        return;
      }
      if (res.length == 0) {
        this.$message.error("请选择物资明细");
        return;
      }
      this.$emit("success", res);
      this.visible = false;
    },
    selectAll() {
      this.arrayTotal.forEach((item) => {
        item.quantity = item.quantityCanUse;
      });
    },
  },
};
</script>

<style>
</style>